<app-header></app-header>
<app-sidenav-assessors *ngIf="this.udata.role=='assessor'"></app-sidenav-assessors>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Hold on while we take you to your CII FACE Account! </p>
</ngx-spinner>
<div id="content" class="app-content" role="main">
  <div class="app-content-body">
    <div class="d-flex align-items-center mar-in-20">
      <div class="col-md-10 my-3">
        <span style="color: #939393;" class="cursor-pointer"
          routerLink="{{udata.role.includes('admin') ? '/list-allocation' : '/assessors-assess-information'}}"><i
            class="fa fa-long-arrow-left" aria-hidden="true"></i>
          Assessment Information
        </span>
        <h2 class="mt-0">Assess Checklist <span *ngIf="section_no">({{"Section "+activeSubsection}})</span></h2>
      </div>
      <div class="col-md-2">
        <app-commonhead [allocation_id]="this.allocated_id" [applicant_id]="this.applicant_id"></app-commonhead>
      </div>
    </div>
    <ul class="nav nav-tabs bb-1" *ngIf="cosection && cosection.length">
      <li class="nav-item" role="presentation" (click)="switchTab('applicant')"
        [class.active]="activeTab == 'applicant'">
        <a>Applicant</a>
      </li>
      <li class="nav-item" role="presentation" (click)="switchTab('coapplicant')"
        [class.active]="activeTab == 'coapplicant'">
        <a>Co-Applicant</a>
      </li>
    </ul>
    <div [class.d-none]="activeTab != 'applicant'">
      <ul class="d-flex bb-0 list-style-none sub-checklist flex-wrap">
        <li class="nav-tabs-item" [class.active]="activeSubsection == item" role="presentation"
          *ngFor="let item of this.section" (click)="getQuestion(item)"><a style="font-size: 14px;">Sec-{{ item }}</a>
        </li>
      </ul>
      <form [formGroup]="Sectionform" formArrayName="score" class="mar-in-20">
        <div *ngIf="floating && currcriteria != 'Criteria 1'" class="col-md-4">
          <div class="form-check  form-switch">
            <input class="form-check-input" type="checkbox" id="copyCheckbox" #copyCheckbox
              [checked]="copyToggleChecked" [disabled]="copyToggleChecked"
              (change)="onCopyValueClick(copyCheckbox.checked, this)">
            <label class="form-check-label" for="copyCheckbox">
              <br>
              <span class="highlight-text">Copy Value</span><br>
              <small>Copy the applicant's answer.</small>
            </label>
          </div>
        </div>
        <ng-container *ngFor="let item of this.subsection;let i=index">
          <div class="col-sm-12 panel" *ngIf="this.subsecs.includes(item?.sub_section_no)">
            <p class="h4" style="margin: 2rem 0; font-size: 19px;">{{ item?.sub_section_no }} : {{
              item?.sub_section_title }}</p>
            <div class="col-md-12 text-danger">
              <div class="col-md-1 b">Qs#</div>
              <div class="col-md-7 b" [class.col-md-5]="currcriteria != 'Criteria 1'">Checkpoint</div>
              <div class="col-md-3 b" *ngIf="currcriteria != 'Criteria 1'">Applicant Response</div>
              <div class="col-md-4 b" [class.col-md-3]="currcriteria != 'Criteria 1'">Assessor Response</div>
            </div>
            <ng-container *ngFor="let item_inner of this.questions">
              <div class="col-md-12" *ngIf="item?.sub_section_no===item_inner?.sub_section_no">
                <div>
                  <div class="col-md-1">{{ item_inner?.question_no }}</div>
                  <div class="col-md-7" [class.col-md-5]="currcriteria != 'Criteria 1'">{{ item_inner?.question_title }}<br /><small>{{
                      item_inner?.question_description }}</small></div>
                  <div class="col-md-3" *ngIf="currcriteria != 'Criteria 1'">{{item_inner?.answer }}</div>
                  <div class="col-md-4" [class.col-md-3]="currcriteria != 'Criteria 1'">
                    <textarea [id]="'copyField_' + item_inner?._id" class="form-control"
                      [(ngModel)]="this.modall[item_inner?._id]" [ngModelOptions]="{standalone: true}"
                      name="{{ item_inner?._id }}" *ngIf="item_inner?.type=='T'"
                      (input)="onChangeControl(item_inner?._id,$event,null)"></textarea>
                    <select [id]="'copyField_' + item_inner?._id" *ngIf="item_inner?.type=='DD'"
                      name="{{ item_inner?._id }}" class="form-control"
                      (change)=" onChangeControl(item_inner?._id,$event,null)"
                      [(ngModel)]="this.modall[item_inner?._id]" [ngModelOptions]="{standalone: true}">
                      <option value="">Choose any option</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Partly">Partly</option>
                    </select>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="col-md-4 scoreDiv">Score</div>
            <div class="col-md-4">
              <label class="switch">
                <input type="checkbox" (click)="getNotApplicable($event,item?.sub_section_no)"
                  [checked]="!this.modelscorestatus[item?.sub_section_no]">
                <span class="slider"></span>
              </label>
              Applicable<br />
              <small>If section is not applicable, Please uncheck the checkbox.</small>
            </div>
            <div class="col-md-4" style="margin-bottom: 10px;">
              <input type="number" class="form-control InputFeild"
                (focusout)="onChangeScore(item?.sub_section_no, $event,null)"
                [(ngModel)]="this.modelscore[item?.sub_section_no]" [min]="5" [max]="95"
                [ngModelOptions]="{standalone: true}" [disabled]="this.modelscorestatus[item?.sub_section_no]" />
              <span class="perSpan">%</span>
              <p class="perText">The above score is in percentage. Fill in the score between 5% - 95%</p>
              <p class="errorText" style="color:red" *ngIf="isInputInvalid(item?.sub_section_no)">Invalid input!
                Please enter a value between 5% and 95%.</p>
            </div>
          </div>
        </ng-container>
        <button *ngIf="floating" (click)="onSubmit()" class="saveBtn floatBtn">Save</button>
      </form>
    </div>
    <div [class.d-none]="activeTab != 'coapplicant'">
      <ul class="d-flex bb-0 list-style-none sub-checklist flex-wrap">
        <li class="nav-tabs-item" role="presentation" [class.active]="activeSubsection == item"
          *ngFor="let item of this.cosection" (click)="getQuestion(item)">
          <a style="font-size: 14px;">Sec-{{ item }}</a>
        </li>
      </ul>
      <form [formGroup]="Sectionform" formArrayName="score">
        <div *ngIf="floating" class="col-md-4">
          <div class="form-check  form-switch">
            <input class="form-check-input" type="checkbox" id="copyCheckbox" #copyCheckbox
              [checked]="copyToggleChecked" [disabled]="copyToggleChecked"
              (change)="onCopyValueClick(copyCheckbox.checked, this)">
            <label class="form-check-label" for="copyCheckbox">
              <br>
              <span class="highlight-text">Copy Value</span><br>
              <small>Copy the applicant's answer.</small>
            </label>
          </div>
        </div>
        <ng-container *ngFor="let item of this.subsection;let i=index">
          <div class="col-sm-12 panel" *ngIf="this.cosubsecs.includes(item?.sub_section_no)">
            <p class="h4" style="margin: 2rem 0; font-size: 19px;">{{ item?.sub_section_no }} : {{
              item?.sub_section_title }}</p>
            <div class="col-md-12 text-danger">
              <div class="col-md-1 b">Qs#</div>
              <div class="col-md-5 b">Checkpoint</div>
              <div class="col-md-3 b">Applicant Response</div>
              <div class="col-md-3 b">Assessor Response</div>
            </div>
            <ng-container *ngFor="let item_inner of this.questions">
              <div class="col-md-12" *ngIf="item?.sub_section_no===item_inner?.sub_section_no">
                <div>
                  <div class="col-md-1">{{ item_inner?.question_no }}</div>
                  <div class="col-md-5">{{ item_inner?.question_title }}<br /><small>{{
                      item_inner?.question_description }}</small></div>
                  <div class="col-md-3">{{item_inner?.answer }}</div>
                  <div class="col-md-3">
                    <textarea [id]="'copyField_' + item_inner?._id" class="form-control"
                      [(ngModel)]="this.modall[item_inner?._id]" [ngModelOptions]="{standalone: true}"
                      name="{{ item_inner?._id }}" *ngIf="item_inner?.type=='T'"
                      (input)="onChangeControl(item_inner?._id,$event,null)"></textarea>
                    <select [id]="'copyField_' + item_inner?._id" *ngIf="item_inner?.type=='DD'"
                      name="{{ item_inner?._id }}" class="form-control"
                      (change)=" onChangeControl(item_inner?._id,$event,null)"
                      [(ngModel)]="this.modall[item_inner?._id]" [ngModelOptions]="{standalone: true}">
                      <option value="">Choose any option</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Partly">Partly</option>
                    </select>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="col-md-4 scoreDiv">Score</div>
            <div class="col-md-4">
              <label class="switch">
                <input type="checkbox" (click)="getNotApplicable($event,item?.sub_section_no)"
                  [checked]="!this.modelscorestatus[item?.sub_section_no]">
                <span class="slider"></span>
              </label>
              Applicable<br />
              <small>If section is not applicable, Please uncheck the checkbox.</small>
            </div>
            <div class="col-md-4" style="margin-bottom: 10px;">
              <input type="number" class="form-control InputFeild"
                (focusout)=" onChangeScore(item?.sub_section_no, $event,null)"
                [(ngModel)]="this.modelscore[item?.sub_section_no]" [min]="5" [max]="95"
                [ngModelOptions]="{standalone: true}" [disabled]="this.modelscorestatus[item?.sub_section_no]" />
              <span class="perSpan">%</span>
              <p class="perText">The above score is in percentage. Fill in the score between 5% - 95%</p>
              <p class="errorText" style="color:red" *ngIf="isInputInvalid(item?.sub_section_no)">Invalid input!
                Please enter a value between 5% and 95%.</p>
            </div>
          </div>
        </ng-container>
        <button *ngIf="floating" (click)="onSubmit()" class="saveBtn floatBtn">Save</button>
      </form>
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="mdl-header">
        <h5 class="modal-title" id="staticBackdropLabel">Sub-sections</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="bb-0 list-style-none sub-checklist">
          <li class="nav-tabs-item" data-dismiss="modal" [class.active]="activeSubsection == item" role="presentation"
          *ngFor="let item of this.section | slice:6" (click)="getQuestion(item)"><a style="font-size: 14px;">Sec-{{ item }}</a>
        </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="mdl-header">
        <h5 class="modal-title" id="staticBackdropLabel">Sub-sections</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="bb-0 list-style-none sub-checklist">
          <li class="nav-tabs-item" role="presentation" data-dismiss="modal"  [class.active]="activeSubsection == item"
          *ngFor="let item of this.cosection | slice:6" (click)="getQuestion(item)">
          <a style="font-size: 14px;">Sec-{{ item }}</a>
        </li>
        </ul>
      </div>
    </div>
  </div>
</div>
