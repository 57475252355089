<app-header></app-header>
<app-sidenav *ngIf="this.udata.role!='assessor'"></app-sidenav>
<app-sidenav-assessors *ngIf="this.udata.role=='assessor'"></app-sidenav-assessors>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Hold on while we take you to your CII FACE Account! </p>
</ngx-spinner>
<div id="content" class="app-content" role="main">
  <div class="app-content-body">
    <div class="d-flex align-items-center mar-in-20">
      <div class="col-md-10 my-3">
        <span style="color: #939393;" class="cursor-pointer"
          routerLink="{{udata.role.includes('admin') ? '/list-allocation' : '/assessors-assess-information'}}"><i
            class="fa fa-long-arrow-left" aria-hidden="true"></i>
          Assessment
          Information</span>
        <h2 class="mt-0">Consensus Score Sheet</h2>
      </div>
      <div class="col-md-2">
        <app-commonhead [allocation_id]="this.allocated_id" [applicant_id]="this.applicant_id"></app-commonhead>
      </div>
    </div>
    <div class="clearfix"></div>
    <ul class="nav nav-tabs bb-1" *ngIf="criteria.length > 1">
      <li class="nav-item" role="presentation" (click)="switchTab('applicant')"
        [class.active]="activeTab == 'applicant'">
        <a>Applicant</a>
      </li>
      <li class="nav-item" role="presentation" (click)="switchTab('coapplicant')"
        [class.active]="activeTab == 'coapplicant'">
        <a>Co-Applicant</a>
      </li>
    </ul>
    <div [class.d-none]="activeTab != 'applicant'">
      <ul class="d-flex bb-0 list-style-none sub-checklist flex-wrap">
        <li role="presentation" class="nav-tabs-item" [class.active]="item?._id.section_no==this.section_no"
          *ngFor="let item of this.section" (click)="getQuestion(item?._id.section_no)"><a
            style="font-size: 14px;">Sec-{{ item?._id.section_no }}</a>
        </li>
        <!-- <li class="nav-tabs-item" data-toggle="modal" data-target="#exampleModal" role="presentation"
          *ngIf="this.section.length>6"><a style="font-size: 14px;"><span>See More</span></a>
        </li> -->
      </ul>
    </div>
    <div [class.d-none]="activeTab != 'coapplicant'">
      <ul class="d-flex bb-0 list-style-none sub-checklist">
        <li role="presentation" class="nav-tabs-item" [class.active]="item?._id.section_no==this.section_no"
          *ngFor="let item of this.section | slice:0:6" (click)="getQuestion(item?._id.section_no)"><a
            style="font-size: 14px;">Sec-{{ item?._id.section_no }}</a>
        </li>
        <li class="nav-tabs-item" data-toggle="modal" data-target="#exampleModal" role="presentation"
          *ngIf="this.section.length>6"><a style="font-size: 14px;"><span>See More</span></a>
        </li>
      </ul>
    </div>
    <div *ngIf="sectionData.length" class="mb-3 mar-in-20">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>{{ this.section_no }}</th>
            <th>Applicable</th>
            <th>Sub Section</th>
            <th *ngFor="let assessor of allocations">
              {{assessor.username}}
            </th>
            <th>Consensus Score (%)</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let itemd of sectionData;let i=index">
            <td>{{ i+1 }}</td>
            <td>{{ itemd?.notApplicable ? 'No' : 'Yes' }}</td>
            <td>{{ itemd.sub_section_title }}</td>
            <td *ngFor="let assessor of allocations">
              <input type="number" [(ngModel)]="suggestionScore[0][itemd?.sub_section_no][assessor.email]"
                aria-describedby="scoreHelp" class="form-control"
                [disabled]="udata._id != assessor._id || isNum(this.SubSecmodel[itemd?.sub_section_no]) || enableAddConsensus || itemd?.notApplicable"
                (input)="getSuggestScore($event,itemd?.sub_section_no,assessor.email)"
                (focusout)="roundoff(itemd?.sub_section_no, assessor.email)" />
              <small id="scoreHelp" class="form-text text-muted">Enter value between 0 and 95</small>
            </td>
            <td>
              <input type="number" class="form-control" [(ngModel)]="SubSecmodel[itemd?.sub_section_no]"
                (input)="getModelScore($event,itemd?.sub_section_no)"
                [disabled]="this.disableStatus || checkDisability(suggestionScore[0][itemd?.sub_section_no]) || itemd?.notApplicable"
                (focusout)="getConsensus(itemd?.sub_section_no)" />
            </td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-success" style="margin-inline-end: 20px;" (click)="saveConsensusScore(true, 'suggestion')" *ngIf="!enableAddConsensus">Save
        Score</button>
      <button class="btn btn-success" style="margin-inline-end: 20px;" (click)="enableConsensus()" *ngIf="saveCons">
        Start Consesus Scoring
      </button>
      <button class="btn btn-success" (click)="saveConsensusScore(true, 'consensus')" style="margin-inline-end: 20px;"
        *ngIf="saveCons && enableAddConsensus">Save Consensus
        Scores</button>
      <button class="btn btn-success" style="margin-inline-end: 20px;" (click)="saveConsensusScore(true, 'consensus')" *ngIf="role==='super admin'">Save
        Consensus Scores</button>
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="mdl-header">
        <h5 class="modal-title" id="staticBackdropLabel">Sub-sections</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="bb-0 list-style-none sub-checklist">
          <li role="presentation" class="nav-tabs-item" data-dismiss="modal" [class.active]="item?._id.section_no==this.section_no"
          *ngFor="let item of this.section | slice:6" (click)="getQuestion(item?._id.section_no)"><a
            style="font-size: 14px;">Sec-{{ item?._id.section_no }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>