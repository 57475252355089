import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import { question } from 'src/app/question';
import { QuestionService } from 'src/app/services/question.service';
import { TosterService } from 'src/app/services/toster.service';
import Swal from 'sweetalert2';
import { process } from '@progress/kendo-data-query';
import { DataBindingDirective } from "@progress/kendo-angular-grid";

@Component({
  selector: 'app-questionaire',
  templateUrl: './questionaire.component.html',
  styleUrls: ['./questionaire.component.css'],
})
export class QuestionaireComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  submitted: boolean = false;
  importContacts: any = [];
  selectedRowData: any = {};
  public gridData: any;
  public gridViewData: any;
  @ViewChild('showModal') showModal!: ElementRef;
  @ViewChild('closeModal') closeModal!: ElementRef;
  allData: any;
  newQuestion: any = {};
  constructor(
    private QuesServer: QuestionService,
    private toast: TosterService
  ) {
  }

  ngOnInit(): void {
    this.QuesServer.viewQuestion().subscribe((item: any) => {
      this.gridData = item.Ques;
      this.gridViewData = item.Ques;
      this.allData = item.Ques;
    })
  }
  importFromFile(bstr: string): XLSX.AOA2SheetOpts {
    /* read workbook */
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
    /* grab first sheet */
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];
    /* save data */
    const data = <XLSX.AOA2SheetOpts>(
      XLSX.utils.sheet_to_json(ws, { header: 1 })
    );
    return data;
  }
  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const data = <any[]>this.importFromFile(bstr);
      const header: string[] = Object.getOwnPropertyNames(new question());
      const importedData = data.slice(1);
      this.importContacts = importedData.map((arr) => {
        const obj: any = {};
        for (let i = 0; i < header.length; i++) {
          const k = header[i];
          obj[k] = arr[i];
        }
        return <any>obj;
      });
      this.QuesServer.bulkInsert(this.importContacts).subscribe(
        (item: any) => {
          this.toast.showInfo(
            'Question Saved.'
          );
          window.location.href = '/questionaire';
          //  this.gridData = this.importContacts;
        }
      );
    };
    reader.readAsBinaryString(target.files[0]);
  }

  onRowClick(event: any): void {
    this.selectedRowData = event.selectedRows[0].dataItem;
    this.showModal.nativeElement.click();
  }

  updateQuestion() {
    this.submitted = true;
    this.selectedRowData.question_title = this.selectedRowData.question_title?.trim();
    this.selectedRowData.question_description = this.selectedRowData.question_description?.trim();
    this.selectedRowData.type = this.selectedRowData.type?.trim();
    if (!this.selectedRowData.question_title || !this.selectedRowData.question_description || !this.selectedRowData.type) {
      return;
    }
    let data = {
      question_title: this.selectedRowData.question_title,
      question_description: this.selectedRowData.question_description,
      type: this.selectedRowData.type,
    }
    this.QuesServer.updateQuestion(data, this.selectedRowData._id).subscribe((resp: any) => {
      Swal.fire('Question has been updated.', '', 'success');
      this.submitted = false;
      this.closeModal.nativeElement.click();
    })
  }

  public onFilter(inputValue: string): void {
    this.gridViewData = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "s_No",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "criteria",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "section_no",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "section_title",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "sub_section_no",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "sub_section_title",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "type",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "question_no",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "question_title",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "question_description",
            operator: "contains",
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }

  addQuestion() {
    this.newQuestion.criteria = this.newQuestion.criteria?.trim();
    this.newQuestion.question_no = this.newQuestion.question_no?.trim();
    this.newQuestion.section_no = this.newQuestion.section_no?.trim();
    this.newQuestion.section_title = this.newQuestion.section_title?.trim();
    this.newQuestion.sub_section_no = this.newQuestion.sub_section_no?.trim();
    this.newQuestion.sub_section_title = this.newQuestion.sub_section_title?.trim();
    this.newQuestion.question_title = this.newQuestion.question_title?.trim();
    this.newQuestion.question_description = this.newQuestion.question_description?.trim();

    this.submitted = true;

    if (
      !this.newQuestion.criteria ||
      !this.newQuestion.question_no ||
      !this.newQuestion.section_no ||
      !this.newQuestion.section_title ||
      !this.newQuestion.sub_section_no ||
      !this.newQuestion.sub_section_title ||
      !this.newQuestion.question_title ||
      !this.newQuestion.type ||
      !this.newQuestion.question_description
    ) {
      return;
    }
    this.QuesServer.add(this.newQuestion).subscribe(
      (item: any) => {
        this.toast.showInfo(
          'Question Saved.'
        );
        this.submitted = false;
        this.newQuestion = {}
      }
    );
  }
}
