<app-header></app-header>
<app-sidenav></app-sidenav>
<div id="content" class="app-content" role="main">
  <div class="app-content-body container">
    <h2>Admin Dashboard</h2>
    <section id="dasboard-top-boxes">
      <!-- <div class="card">
        <img src="../../../assets/img/background-default.png" style="width:40%;" />
      </div> -->
      <div class="first-sec">
        <div class="top-box">
          <div class="title">
            <h6>Total Registrations</h6>
          </div>
          <div class="footer-number color1">
            <h5>{{ this.totalApplicant }}</h5>
          </div>
        </div>
        <div class="top-box">
          <div class="title">
            <h6>LOI Submission</h6>
          </div>
          <div class="footer-number color2">
            <h5>{{ this.totalLOI }}</h5>
          </div>
        </div>
        <div class="top-box">
          <div class="title">
            <h6>Introduction Sheet Submitted</h6>
          </div>
          <div class="footer-number color1">
            <h5>{{ this.introSubmitted }}</h5>
          </div>
        </div>
        <div class="top-box">
          <div class="title">
            <h6>Checklist</h6>
          </div>
          <div class="footer-number color2">
            <h5>{{ this.totalChecklist }}</h5>
          </div>
        </div>
        <div class="top-box">
          <div class="title">
            <h6>Joint Applicant</h6>
          </div>
          <div class="footer-number color3">
            <h5>{{jointApplicant}}</h5>
          </div>
        </div>
        <div class="top-box">
          <div class="title">
            <h6>Assessor</h6>
          </div>
          <div class="footer-number color4">
            <h5>{{ this.detail?.ass_count }}</h5>
          </div>
        </div>
        <!-- <div class="top-box">
          <div class="title">
            <h6>Calibrator</h6>
          </div>
          <div class="footer-number color4">
            <h5>0</h5>
          </div>
        </div> -->
      </div>
    </section>
    <section id="applicant-sec">
      <div class="applicant-title">
        <h6>APPLICANT</h6>
      </div>
      <div class="tab-area">
        <div>
          <ul class="nav tabs-list" role="tablist">
            <li role="presentation" class="mx2 active">
              <a href="#home" aria-controls="home" role="tab" data-toggle="tab">Sector Wise</a>
            </li>
            <li class="mx2" role="presentation"><a href="#profile" aria-controls="profile" role="tab"
                data-toggle="tab">Criteria Wise</a></li>
            <li class="mx2" role="presentation"><a href="#messages" aria-controls="messages" role="tab"
                data-toggle="tab">State
                Wise</a></li>
          </ul>
          <div class="tab-content tab-cont-center">
            <div role="tabpanel" class="tab-pane" id="profile">
              <div>
                <div>
                  <div style="display: block; margin: auto;height: auto;width: 41%;">
                    <canvas baseChart [data]="doughnutChartData1" [type]="doughnutChartType" [options]="this.options">
                    </canvas>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" class="tab-pane active" id="home">
              <div>
                <div>
                  <div style="display: block; margin: auto;height: auto;width: 41%;" #homeChart>
                    <canvas baseChart [data]="doughnutChartData" [type]="doughnutChartType" [options]="this.options">
                    </canvas>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" class="tab-pane" id="messages">
              <div>
                <div>
                  <div style="display: block; margin: auto;height: auto;width: 41%;">
                    <canvas baseChart [data]="doughnutChartData2" [type]="doughnutChartType" [options]="this.options">
                    </canvas>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="first-sec sectionPadding">
      <ng-container *ngFor="let classi of this.detail?.classificationData;let i=index">
        <div class="top-box" *ngIf="classi._id.classification">
          <div>
            <div class="title">
              <h6>{{classi._id.classification}}</h6>
            </div>
            <div class="footer-number color{{i%2==0?1:2}}">
              <h5>{{classi.count}}</h5>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <section id="table-sec">
      <div class="row">
        <div class="col-sm-4">
          <div class="table-title">
            <h6>Applicants (Sector Wise)</h6>
          </div>
          <div class="table-area">
            <table class="table" style="margin: 0%;">
              <thead>
                <tr>
                  <th>Sector </th>
                  <th>All</th>
                  <th>Mc</th>
                  <th>Sm</th>
                  <th>Md</th>
                  <th>Lg</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of this.detail?.app_sector">
                  <td scope="row" *ngIf="item._id.sector==null?false:true">
                    <span>
                      <!-- <img src="../../../assets/asset/img/company.png" /> -->
                    </span> {{ item._id.sector }}
                  </td>
                  <td class="center-td" *ngIf="item._id.sector==null?false:true">{{ item.scount }}</td>
                  <td class="center-td" *ngIf="item._id.sector==null?false:true">{{ item.Micro }}</td>
                  <td class="center-td" *ngIf="item._id.sector==null?false:true">{{ item.Small }}</td>
                  <td class="center-td" *ngIf="item._id.sector==null?false:true">{{ item.Medium }}</td>
                  <td class="center-td" *ngIf="item._id.sector==null?false:true">{{ item.Large }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="table-title">
            <h6>Applicants (Criteria Wise)</h6>
          </div>
          <div class="table-area">
            <table class="table" style="margin: 0%;">
              <thead>
                <tr>
                  <th>Criteria </th>
                  <th>All</th>
                  <!-- <th>Mc</th>
                  <th>Sm</th>
                  <th>Md</th>
                  <th>Lg</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of this.detail?.app_criteria">
                  <td scope="row" *ngIf="item._id.criteria==null?false:true"><span>
                      <!-- <img src="../../../assets/asset/img/company.png" /> -->
                    </span> {{ item._id.criteria }}</td>
                  <td class="center-td" *ngIf="item._id.criteria==null?false:true">{{ item.count }}</td>
                  <!-- <td class="center-td" *ngIf="item._id.criteria==null?false:true">{{ item.Micro }}</td>
                  <td class="center-td" *ngIf="item._id.criteria==null?false:true">{{ item.Small }}</td>
                  <td class="center-td" *ngIf="item._id.criteria==null?false:true">{{ item.Medium }}</td>
                  <td class="center-td" *ngIf="item._id.criteria==null?false:true">{{ item.Large }}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="table-title">
            <h6>Applicants (State Wise)</h6>
          </div>
          <div class="table-area">
            <table class="table" style="margin: 0%;">
              <thead>
                <tr>
                  <th>State </th>
                  <th>All</th>
                  <th>Mc</th>
                  <th>Sm</th>
                  <th>Md</th>
                  <th>Lg</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of this.detail?.app_state">
                  <td scope="row" *ngIf="item._id.applicantState==null?false:true"><span>
                      <!-- <img src="../../../assets/asset/img/company.png" /> -->
                    </span> {{ item._id.applicantState }}</td>
                  <td class="center-td" *ngIf="item._id.applicantState==null?false:true">{{ item.count }}</td>
                  <td class="center-td" *ngIf="item._id.applicantState==null?false:true">{{ item.Micro }}</td>
                  <td class="center-td" *ngIf="item._id.applicantState==null?false:true">{{ item.Small }}</td>
                  <td class="center-td" *ngIf="item._id.applicantState==null?false:true">{{ item.Medium }}</td>
                  <td class="center-td" *ngIf="item._id.applicantState==null?false:true">{{ item.Large }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <section id="assisment-table">
      <div class="applicants-title">
        <h6>ASSESSMENTS</h6>
      </div>
      <div class="applicants-data">
        <table class="table">
          <thead>
            <tr>
              <th>APPLICANT</th>
              <th>Team</th>
              <th>SCHEDULE From</th>
              <th>SCHEDULE To</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let allval of AllocationList">
            <tr *ngIf="allval?.applicantData[0]?.firstName">
              <td>
                <span class="app-id">Name: {{ allval?.applicantData[0]?.firstName}}</span><br />
                <span class="app-id">Org: {{ allval?.applicantData[0]?.organizationName}}</span><br />
                <span>Email: {{ allval?.applicantData[0]?.email}}</span><br />
                <span>{{ allval?.applicantData[0]?.criteria}}, {{ allval?.applicantData[0]?.sector}}</span>
              </td>
              <td>
                <table class="table table-bordered nowrap">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Designation</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let ab of allval.assessorData">
                      <tr *ngIf="ab.firstName">
                        <td *ngIf="checkArray(allval.allocationlistData,ab._id,'allocationliststatus')!='rejected'">
                          {{ ab.firstName }}</td>
                        <td *ngIf="checkArray(allval.allocationlistData,ab._id,'allocationliststatus')!='rejected'">
                          {{ ab.assessorsEmail }}</td>
                        <td *ngIf="checkArray(allval.allocationlistData,ab._id,'allocationliststatus')!='rejected'">
                          <label class="btn-xs btn-success"
                            *ngIf="checkArray(allval.allocationlistData,ab._id,'teamleader')==''?false:true">{{
                            checkArray(allval.allocationlistData,ab._id,'teamleader') }}</label><label
                            class="btn-xs btn-info"
                            *ngIf="checkArray(allval.allocationlistData,ab._id,'calibrator')==''?false:true">{{
                            checkArray(allval.allocationlistData,ab._id,'calibrator') }}</label>
                        </td>
                        <td *ngIf="checkArray(allval.allocationlistData,ab._id,'allocationliststatus')!='rejected'">
                          {{
                          checkArray(allval.allocationlistData,ab._id,'allocationliststatus') }}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </td>
              <td>
                {{ allval.period_from|date:'dd-MM-yyyy' }}
              </td>
              <td> {{ allval.period_to|date:'dd-MM-yyyy' }}</td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </section>
    <!--
    <section id="pie-with-tab">
      <div class="row">
        <div class="col-sm-4">
          <div class="applicant-title">
            <h6>ASSESSOR</h6>
          </div>
          <div class="tab-area">
            <div>
              <ul class="nav tabs-list" role="tablist">
                <li role="presentation" class="active"><a href="#Sector" aria-controls="Sector" role="tab"
                    data-toggle="tab">Sector
                    Wise</a>
                </li>
                <li class="mx1" role="presentation"><a href="#State" aria-controls="State" role="tab"
                    data-toggle="tab">State Wise</a></li>
              </ul>
              <div class="tab-content tab-cont-center">
                <div role="tabpanel" class="tab-pane active" id="Sector">
                  <div>
                    <div>
                      <div style="display: block; margin: auto;height: auto;width: 41%;">
                        <canvas baseChart [data]="doughnutChartData_1" [type]="doughnutChartType">
                        </canvas>
                      </div>
                    </div>
                  </div>
                </div>
                <div role="tabpanel" class="tab-pane" id="State">
                  <div>
                    <div>
                      <div style="display: block; margin: auto;height: auto;width: 41%;">
                        <canvas baseChart [data]="doughnutChartData_2" [type]="doughnutChartType">
                        </canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="table-title">
            <h6>Assessor (Sector Wise)</h6>
          </div>
          <div class="table-area-height">
            <table class="table" style="margin: 0%;">
              <tbody>
                <tr>
                  <td scope="row"><span><img src="../../../assets/asset/img/user.png" /></span> No Data Found</td>
                  <td class="center-td"></td>
                  <td class="center-td"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="table-title">
            <h6>Assessor (State Wise)</h6>
          </div>
          <div class="table-area-height">
            <table class="table" style="margin: 0%;">
              <tbody>
                <tr>
                  <td scope="row"><span><img src="../../../assets/asset/img/user.png" /></span> No Data Found</td>
                  <td class="center-td"></td>
                  <td class="center-td"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <section id="pie-with-tab" class="latst-sec">
      <div class="row">
        <div class="col-sm-4">
          <div class="applicant-title">
            <h6>CALIBRATOR</h6>
          </div>
          <div class="tab-area">
            <div>
              <ul class="nav tabs-list" role="tablist">
                <li role="presentation" class="active"><a href="#Sector2" aria-controls="Sector2" role="tab"
                    data-toggle="tab">Sector
                    Wise</a>
                </li>
                <li class="mx1" role="presentation"><a href="#State2" aria-controls="State2" role="tab"
                    data-toggle="tab">State Wise</a></li>
              </ul>
              <div class="tab-content tab-cont-center">
                <div role="tabpanel" class="tab-pane active" id="Sector2">
                  <div>
                    <div>
                      <div style="display: block; margin: auto;height: auto;width: 41%;">
                        <canvas baseChart [data]="doughnutChartData_1" [type]="doughnutChartType">
                        </canvas>
                      </div>
                    </div>
                  </div>
                </div>
                <div role="tabpanel" class="tab-pane" id="State2">
                  <div>
                    <div>
                      <div style="display: block; margin: auto;height: auto;width: 41%;">
                        <canvas baseChart [data]="doughnutChartData_1" [type]="doughnutChartType">
                        </canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="table-title">
            <h6>Calibrator (Sector Wise)</h6>
          </div>
          <div class="table-area-height">
            <table class="table" style="margin: 0%;">
              <tbody>
                <tr>
                  <td scope="row"><span><img src="../../../assets/asset/img/user.png" /></span> No Data Found</td>
                  <td class="center-td"></td>
                  <td class="center-td"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="table-title">
            <h6>Calibrator (State Wise)</h6>
          </div>
          <div class="table-area-height">
            <table class="table" style="margin: 0%;">
              <tbody>
                <tr>
                  <td scope="row"><span><img src="../../../assets/asset/img/user.png" /></span> No Data Found</td>
                  <td class="center-td"></td>
                  <td class="center-td"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
-->
  </div>
</div>
