<app-header></app-header>
<app-sidenav *ngIf="this.udata.role!='assessor'"></app-sidenav>
<app-sidenav-assessors *ngIf="this.udata.role=='assessor'"></app-sidenav-assessors>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Hold on while we get your data! </p>
</ngx-spinner>
<div id="content" class="app-content" role="main">
  <div class="app-content-body mar-in-20">
    <div>
      <h2>Recommendation Report</h2>
    </div>
    <ul class="nav nav-tabs bb-1" *ngIf="criteriaArray.length > 1">
      <li class="nav-item" role="presentation" *ngFor="let crt of criteriaArray" [class.active]="criteria == crt"
        (click)="criteriaChange(crt)">
        <a>{{crt}}</a>
      </li>
    </ul>
    <div class="clearfix"></div>
    <div class="lock-header mt-4">
      <table class="table table-bordered" *ngIf="criteria">
        <tbody>
          <ng-container *ngFor="let item of sectors; index as s">
            <tr class="header-row" [ngStyle]="{'background': item.includes('Rising Star') ? sectorColor[s-1] : sectorColor[s]}" (click)="toggleCollapse(item)">
              <th [attr.colspan]="headers[criteria].mapping.length + 8">
                <span style="position: sticky; top: 0; left: 30px;">
                  {{item}}
                  <i style="margin-left: 20px;" [ngClass]="collapsed[item] ? 'fa fa-chevron-down' : 'fa fa-chevron-up'" aria-hidden="true"></i>
                </span>
              </th>
            </tr>

            <ng-container *ngIf="!collapsed[item]">
              <tr>
                <th style="background-color: #D8E4BC;">Rank</th>
                <th class="headcol" style="position: sticky; top: 0; background-color: #D8E4BC;">Unit Name</th>
                <th style="background-color: #D8E4BC;">Overall Score</th>
                <th style="background-color: #D8E4BC;" *ngFor="let item of headers[criteria].mapping">{{item}}</th>
                <th style="background-color: #D8E4BC;">Reco Team</th>
                <th style="background-color: #D8E4BC;">Reco Calibrator</th>
                <th style="background-color: #D8E4BC;">Reco Reviewer</th>
                <th style="background-color: #D8E4BC;">Reco by Pre-Jury</th>
              </tr>
              <tr *ngFor="let applicant of applicants[item]; index as i">
                <td>{{applicant.recommendation?.rank || i+1}}</td>
                <td [class.rising-star]="this.compareDates(applicant.operationForm)" style="position: sticky; left: 0; background: #d0dcfd;">
                  {{applicant.firstName}}, {{applicant.applicantCity}}
                </td>
                <td>{{applicant.generatedScore['Total']}}</td>
                <td *ngFor="let section of headers[criteria].section">
                  {{applicant.generatedScore[section]}}
                </td>
                <td>{{applicant.recommendation?.team}}</td>
                <td>{{applicant.recommendation?.calibrator}}</td>
                <td>
                  <textarea class="form-control resizable" name="reviewer" id="reviewer" (blur)="saveRecommendation(applicant)" [(ngModel)]="recommendation[applicant._id].reviewer" [disabled]="!applicant.recommendation?.calibrator"></textarea>
                </td>
                <td>
                  <textarea class="form-control resizable" name="prejury" id="prejury" (blur)="saveRecommendation(applicant)" [(ngModel)]="recommendation[applicant._id].prejury" [disabled]="!applicant.recommendation?.calibrator"></textarea>
                </td>
              </tr>
            </ng-container>

            <tr class="footer-row">
              <th [attr.colspan]="headers[criteria].mapping.length + 7"></th>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <button (click)="showSuccess()" class="btn btn-primary" *ngIf="criteria">Save</button>
    </div>
  </div>
</div>
