<app-header></app-header>
<app-sidenav-assessors></app-sidenav-assessors>
<div id="content" class="app-content" role="main">
  <div class="app-content-body container">
    <div class="d-flex align-items-center">
      <div class="col-md-10 my-3">
        <span style="color: #939393;" class="cursor-pointer"
          routerLink="{{udata.role.includes('admin') ? '/list-allocation' : '/assessors-assess-information'}}"><i
            class="fa fa-long-arrow-left" aria-hidden="true"></i>
          Assessment
          Information</span>
        <h2 class="mt-0">Team Allocation</h2>
      </div>
      <div class="col-md-2">
        <app-commonhead [allocation_id]="this.allocationId" [applicant_id]="this.applicant_id"></app-commonhead>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="col-md-12">
      <div class="row">
        <div class="row">
          <div class="col-md-12 ">
            <div class="panel">
              <div class="panel-heading panel-title">
                Allocated Team
              </div>
              <div class="responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Assessor Details</th>
                      <th [attr.colspan]="colspanValue">Section</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="colspanValue > 1">
                      <td></td>
                      <td *ngFor="let name of section | keyvalue">{{name.key}}</td>
                    </tr>
                    <tr *ngFor="let item of allocatedlist;let i=index">
                      <td>
                        {{ item.assessorData[0].firstName }}<br />
                        <i class="fa fa-hand-o-right"></i> {{ item.allocationliststatus }}
                      </td>
                      <td *ngFor="let usersection of section | keyvalue">
                        <ul class="list-inline" *ngIf="item.allocationliststatus!='rejected'">
                          <li class="list-inline-item" *ngFor="let item1 of usersection.value; index as j">
                            <input id="{{item._id+usersection.key+item1}}" type="checkbox"
                              [checked]="amIChecked(item, item1, usersection.key)"
                              (click)="updateSection($event,item._id,i,subsecs[usersection.key][item1], usersection.key)"
                              [value]="item1" [disabled]="this.allocatedlist[0].period_from || udata.role.includes('admin')" />
                            <label for="{{item._id+usersection.key+item1}}"><b>Section:</b> {{ item1 }}</label>
                            <ul *ngIf="checkToShow(item, item1, usersection.key)">
                              <li *ngFor="let subItem of subsecs[usersection.key][item1]">
                                <input type="checkbox" id="{{item._id+usersection.key+item1+subItem}}"
                                  [disabled]="checkdissub(item._id,subItem,usersection.key) || udata.role.includes('admin')"
                                  [checked]="checksub(item._id,subItem,usersection.key)" [value]="subItem"
                                  (click)="updateSubSection($event,item._id,i,usersection.key)" />
                                <label for="{{item._id+usersection.key+item1+subItem}}">{{ subItem }}</label>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="panel" style="margin-bottom: 50px;">
          <div class="panel-body">
            <form [formGroup]="teamAllocation" (ngSubmit)="updateAllocation()">
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Period From</label> <small id="emailHelp"
                      class="form-text text-muted"> :{{this.min|date:'dd-MM-yyyy'}}</small>
                    <input type="date" formControlName="period_from" class="form-control" id="exampleInputEmail1"
                      aria-describedby="emailHelp" placeholder="Enter Date"
                      (input)="getDate($event,'')" (keydown)="preventKeyboardInput($event)">
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group" *ngIf="min">
                    <label for="exampleInputEmail2">Period To</label><small id="emailHelp_1"
                      class="form-text text-muted"> :{{this.to|date:'dd-MM-yyyy'}}</small>
                    <input type="date" class="form-control" formControlName="period_to" id="exampleInputEmail2"
                      aria-describedby="emailHelp_1" placeholder="Enter email" [min]="min | date: 'yyyy-MM-dd'"
                      (input)="getDateto($event,'')" (keydown)="preventKeyboardInput($event)">
                  </div>
                </div>
                <div class="col-md-2">
                  <button class="btn btn-success w-100" style="margin-top:2rem;" *ngIf="!udata.role.includes('admin')">Save</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
