import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllocationService } from '../services/allocation.service';
import { ApplicantService } from '../services/applicant.service';
import { QuestionService } from '../services/question.service';
import { TosterService } from '../services/toster.service';
import { noWhitespaceValidator } from 'src/shared/whitespace.validator';
import { CoApplicantService } from '../services/co-applicant.service';
@Component({
  selector: 'app-checklist-summary',
  templateUrl: './checklist-summary.component.html',
  styleUrls: ['./checklist-summary.component.css'],
})
export class ChecklistSummaryComponent implements OnInit {
  applicant_id: any;
  section: any;
  criteria: any = [];
  app_email: any;
  questions: any = [];
  subsection: any;
  answer: any;
  public model: any = {};
  modall: any = [];
  public modelscore: any = {};
  modallscore: any = [];
  udata: any;
  section_no: any;
  allocated_id: any;
  assessor_data: any = [];
  subSections: any;
  subdone: any = [];
  assessment_data: any = [];
  all_scores: any = [];
  scoreOne: any;
  SubSecmodel: any = {};
  qmodel: any = {};
  isCallibrator: boolean = false;
  isAdmin: boolean = false;
  public current_sec: any;
  remarkForm!: FormArray;
  remark!: FormGroup;
  isloading: Boolean = false;
  tl: any;
  textareas: any = [];
  emails: Array<any> = [];
  HighScrmodel: any;
  highScore: any = 0;
  count: any = 0;
  finalScore: any;
  conper: any = 0;
  encounteredSubSectionNos = new Set();
  firstOccurrences: { [key: string]: number } = {};
  isFirstOccurrences: { [key: string]: boolean } = {};
  version: any = '1';
  activeTab: string = 'applicant';
  calibrator_remarks: any = {};
  admin_remarks: any = {};
  canChange: boolean = false;
  questModels: any = {}
  headers: any = {
    'Criteria 1': {
      A: 'General Regulatory Requirements',
      B: 'Food Safety Practices',
    },
    'Criteria 11': {
      A: 'Statutory, Regulatory and Social Compliance',
      B: 'Laboratory Management',
      C: 'Leadership and Best Practices in Laboratory Initiatives',
    },
    'Criteria 2A for SMB': {
      '01': 'General Regulatory Requirement',
      '02': 'Location of Food counters and Kitchen',
      '03': 'Working Surface',
      '04': 'Facilities',
      '05': 'Equipment & Utensils',
      '06': 'Incoming Material Control',
      '07': 'Control of Operation',
      '08': 'Risk Based Approach',
      '09': 'Allergen Management',
      '10': 'Personal Hygiene',
      '11': 'Washing & Cleaning',
      '12': 'Pest Management',
      '13': 'Measures to prevent cross contaminations',
      '14': 'Display of Food',
      '15': 'Emergency Preparedness',
      '16': 'Management & Supervision',
      '17': 'Eating facilities for Customers',
      '18': 'People, Competence & Awareness',
      '19': 'Product Information and Customer Awareness',
      '20': 'Cont Imp, System, Tools and Techniques',
      '21': 'Recognition of People',
      '22': 'Canteen Management in Food Safety and Sustainability'
    },
    'Criteria 2 for SMB': {
      A: 'Self-Declaration',
      B1: 'Pre-Requisite Programs',
      B2: 'Hazard Analysis and Critical Control Point',
      B3: 'Food Safety Management System Structure',
      C: 'Sector Specific Good Manufacturing Practices',
      D: 'Change Management Initiatives'
    },
    'Criteria 3 Large': {
      A: 'Self-Declaration',
      B1: 'Pre-Requisite Programs',
      B2: 'Hazard Analysis and Critical Control Point',
      B3: 'Food Safety Management System Structure',
      C: 'Sector Specific Good Manufacturing Practices',
      D: 'Change Management Initiatives'
    },
    'Criteria 3 for SMB': {
      A: 'Self-Declaration',
      B1: 'Pre-Requisite Programs',
      B2: 'Hazard Analysis and Critical Control Point',
      B3: 'Food Safety Management System Structure',
      C: 'Sector Specific Good Manufacturing Practices',
      D: 'Change Management Initiatives'
    },
    'Criteria 4': {
      A: 'Social & Regulatory Requirement',
      B1: 'Pre-Requisite Programs',
      B2: 'Hazard Analysis and Critical Control Point',
      B3: 'Food Safety Management System Structure',
      C: 'Sector Specific Good Manufacturing Practices',
      D: 'Change Management Initiatives'
    },
    'Criteria 5': [],
    'Criteria 7': {
      A: 'Self-Declaration',
      B4: 'Management of Partnerships',
    },
    'Criteria 9': {
      A: 'Statutory & Regulatory Requirements & Social Compliance',
      B1: 'Pre Requisite Programs',
      B2: 'Hazard Analysis and Critical Control Point',
      B3: 'Food Safety Management System Structure',
      C: 'Good Manufacturing Practices (GMP)',
      D: 'Change Management Initiatives'
    },
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private quest: QuestionService,
    private applicant: ApplicantService,
    private _Activatedroute: ActivatedRoute,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toast: TosterService,
    public allocation: AllocationService,
    private coapp: CoApplicantService
  ) {
  }
  ngOnInit(): void {
    this.spinner.show();
    this.udata = localStorage.getItem('userdata');
    this.udata = JSON.parse(this.udata);
    if (this.udata?.role?.includes('admin')) this.isAdmin = true;
    let email = this.udata.email;
    this.applicant_id = this._Activatedroute.snapshot.paramMap.get('id');
    this.allocated_id = this._Activatedroute.snapshot.paramMap.get('_id');
    this.tl = this._Activatedroute.snapshot.paramMap.get('tl');
    this.allocation
      .getAllAllocationByAssessorOne(this.allocated_id)
      .subscribe((item: any) => {
        item.result.map((om: any) => {
          if (om.assessor_id == this.udata._id) {
            if (om.calibrator) {
              this.isCallibrator = true;
            }
            if (om.calibrator || om.teamleader) {
              this.canChange = true;
            }
          }
        });
      });
    this.applicant
      .GetAdminApplicantSingle(this.applicant_id)
      .subscribe((data: any) => {
        if (
          data.applicanData[0].criteria != '' &&
          data.applicanData[0].userStatus === true
        ) {
          this.criteria = [data.applicanData[0].criteria];
          this.app_email = data.applicanData[0].email;
          this.quest
            .sectionGroupbyCriteria({
              criteria: this.criteria[0],
            })
            .subscribe((elem: any) => {
              this.section = elem;
              this.coapp
                .listCoApplicant(data.applicanData[0]._id)
                .subscribe((resp: any) => {
                  if (resp.result.length) {
                    let criterias = resp.result[0].co_criteria;
                    this.criteria = this.criteria.concat(criterias);
                  }
                  this.spinner.hide();
                });
            });
        }
      });
  }
  isFirstOccurrence(subSectionNo: any, index: number): boolean {
    if (!(subSectionNo in this.firstOccurrences)) {
      this.firstOccurrences[subSectionNo] = index;
      return true;
    } else {
      if (this.firstOccurrences[subSectionNo] == index) return true;
      else return false;
    }
  }
  checkass(item: any, itemd: any) {
    if (item[this.activeTab == 'applicant' ? 'sub_section' : 'cosub_section'].includes(itemd.sub_section_no)) return true;
    else return false;
  }
  getQuestion(sec: any) {
    this.spinner.show()
    this.admin_remarks = undefined;
    this.calibrator_remarks = undefined;
    let criteria = this.criteria[0];
    if (this.activeTab == 'coapplicant') {
      criteria = this.criteria[1];
    }
    this.questions = [];
    this.questModels = {};
    this.firstOccurrences = {};
    this.isloading = false;
    this.qmodel = {};
    this.current_sec = sec;
    this.quest
      .questionGroupbyCriteria({ section_no: sec, criteria: criteria })
      .subscribe((ques_data: any) => {
        ques_data.map((element: any) => {
          this.qmodel[element?.ques?.[0].id] = '';
        });
        this.questions = ques_data;
        this.questModels = {};
        this.quest
          .getAllocationAssessor({
            allocation_id: this.allocated_id,
          })
          .subscribe((itemx: any) => {
            this.assessor_data = itemx;
            this.quest
              .getAssessment({
                criteria: criteria,
                section_no: sec,
                applicant_id: this.applicant_id,
                active: this.activeTab,
              })
              .subscribe((itemassm: any) => {
                this.assessment_data = itemassm;
                this.emails = [];
                if (itemassm.length) {
                  this.admin_remarks = itemassm[0]?.admin_remark?.[0] || {};
                  this.calibrator_remarks = itemassm[0]?.calibrator_remark?.[0] || {};
                } else {
                  this.admin_remarks = undefined;
                  this.calibrator_remarks = undefined;
                }
                itemassm.map((itemss: any, i: any, assessment: any) => {
                  this.emails.push(itemss.email);
                  if (i + 1 === assessment.length) {
                    this.isloading = true;
                  }
                });
                for (const assessor of itemx) {
                  if (assessor.assessor_data[0].assessorsEmail) {
                    this.questModels[assessor.assessor_data[0].assessorsEmail] = {};
                    let assessment = itemassm.find((assessment: any) => assessment.email == assessor.assessor_data[0].assessorsEmail);
                    for (const quest of this.questions) {
                      // if (assessor.sub_section.includes(quest.ques[0].sub_section_no)) {
                      //   try {
                      //     this.questModels[assessor.assessor_data[0].assessorsEmail][quest.ques[0].id] = assessment.assessment[0][quest.ques[0].id];
                      //   } catch (err) {}
                      // }
                      // if (assessor.cosub_section.includes(quest.ques[0].sub_section_no)) {
                      //   try {
                      //     this.questModels[assessor.assessor_data[0].assessorsEmail][quest.ques[0].id] = assessment.assessment[0][quest.ques[0].id];
                      //   } catch (err) {}
                      // }
                      if (assessor.sub_section && assessor.sub_section.includes(quest.ques[0].sub_section_no)) {
                        const email = assessor.assessor_data[0]?.assessorsEmail;
                        const questId = quest.ques[0].id;

                        // Ensure questModels[assessor.assessor_data[0].assessorsEmail] exists
                        if (email && !this.questModels[email]) {
                          this.questModels[email] = {};
                        }

                        if (email && questId && !this.questModels[email][questId]) {
                          this.questModels[email][questId] = assessment?.assessment[0][questId];
                        } else {
                          this.questModels[email][questId] = assessment?.assessment[0][questId];
                        }
                      }

                      if (assessor.cosub_section && assessor.cosub_section.includes(quest.ques[0].sub_section_no)) {
                        const email = assessor.assessor_data[0]?.assessorsEmail;
                        const questId = quest.ques[0].id;

                        // Ensure questModels[assessor.assessor_data[0].assessorsEmail] exists
                        if (email && !this.questModels[email]) {
                          this.questModels[email] = {};
                        }

                        // Ensure questModels[assessor.assessor_data[0].assessorsEmail][quest.ques[0].id] exists
                        if (email && questId && !this.questModels[email][questId]) {
                          this.questModels[email][questId] = assessment.assessment[0][questId];
                        } else {
                          // If it already exists, update the value (if necessary)
                          this.questModels[email][questId] = assessment.assessment[0][questId];
                        }
                      }
                    }
                  }
                }
                this.spinner.hide();
              });
          });
      });
    this.quest
      .getSubSection({
        criteria: criteria,
        section_no: sec,
      })
      .subscribe((itemQ: any) => {
        this.subSections = itemQ;
        this.quest
          .getConsensusScore({
            allocation_id: this.allocated_id,
            criteria: criteria,
            section_no: sec,
          })
          .subscribe((itemvalue: any) => {
            if (itemvalue.length == 0) {
              this.subSections.map((ssItem: any) => {
                this.SubSecmodel[ssItem._id.sub_section_no] = '0';
              });
            } else {
              this.SubSecmodel = itemvalue.con_score[0].Conscore[0];
              for (var el in this.SubSecmodel) {
                if (this.SubSecmodel.hasOwnProperty(el)) {
                  this.conper += parseFloat(this.SubSecmodel[el]);
                }
              }
            }
            this.quest
              .getHighScore({
                criteria: criteria,
                section_no: sec,
              })
              .subscribe((hitem: any) => {
                this.HighScrmodel = hitem[0]?.score[0];
                for (var el in this.HighScrmodel) {
                  if (this.HighScrmodel.hasOwnProperty(el)) {
                    this.highScore += parseFloat(this.HighScrmodel[el]);
                    this.count +=
                      (parseFloat(
                        this.SubSecmodel?.[el] == 'NA'
                          ? 0
                          : this.SubSecmodel?.[el] == ''
                            ? 0
                            : this.SubSecmodel?.[el]
                      ) /
                        100) *
                      parseFloat(this.HighScrmodel?.[el]);
                    if (this.finalScore) {
                      this.finalScore.push({
                        subsectionno: el,
                        highestscore: parseFloat(this.HighScrmodel?.[el]),
                        score:
                          (parseFloat(
                            this.SubSecmodel?.[el] == 'NA'
                              ? 0
                              : this.SubSecmodel?.[el] == ''
                                ? 0
                                : this.SubSecmodel?.[el]
                          ) /
                            100) *
                          parseFloat(this.HighScrmodel[el]),
                        consensusscore: this.SubSecmodel[el],
                        chkStatus: false,
                      });
                    }
                  }
                }
              });
            this.quest
              .getGeneratedScore({
                allocation_id: this.allocated_id,
                criteria: criteria,
                section_no: sec,
              })
              .subscribe((gitem: any) => {
                if (gitem.length == 0) {
                  this.version = '1';
                } else {
                  this.version = ++gitem[0].version;
                }
              });
            this.spinner.hide();
          });
      });
  }
  index(id: any) {
    return this.emails.indexOf(id);
  }
  checkEmail(id: any) {


    if (this.emails.indexOf(id) !== -1) {
      return true;
    } else return false;
  }
  checkexist(id: any) {
    if (id in this.qmodel) return true;
    else return false;
  }
  saveAssessment() {
    this.spinner.show();
    let criteria = this.criteria[0];
    if (this.activeTab == 'coapplicant') {
      criteria = this.criteria[1];
    }
    this.quest.updateAsses({
      criteria: criteria,
      section_no: this.current_sec,
      applicant_id: this.applicant_id,
      updates: this.questModels
    }).subscribe({
      next: () => {
        this.spinner.hide()
      },
      error: (err) => {

        this.spinner.hide()
      }
    })
  }
  onSubmit() {
    this.spinner.show()
    let criteria = this.criteria[0];
    if (this.activeTab == 'coapplicant') {
      criteria = this.criteria[1];
    }
    this.emails.map((email: any, index) => {
      this.quest
        .addCalRemark({
          calibrator_remark:
            this.calibrator_remarks,
          criteria: criteria,
          section_no: this.current_sec,
          applicant_id: this.applicant_id,
        })
        .subscribe({
          next: (item: any) => {
            this.toast.showInfo('Remark Saved');
            this.spinner.hide()
          },
          error: (err) => {

            this.spinner.hide()
          }
        });
    });
  }
  onAdminSubmit() {
    this.spinner.show();
    let criteria = this.criteria[0];
    if (this.activeTab == 'coapplicant') {
      criteria = this.criteria[1];
    }
    this.onSubmit();
    this.emails.map((email: any, index) => {
      this.quest
        .addAdminRemark({
          admin_remark:
            this.admin_remarks,
          criteria: criteria,
          section_no: this.current_sec,
          applicant_id: this.applicant_id,
        })
        .subscribe({
          next: (item: any) => {
            this.toast.showInfo('Remark Saved');
            this.spinner.hide()
          },
          error: (err) => {

            this.spinner.hide()
          }
        });
    });
  }
  getScoreBySubSection(id: any, email: any) {
    this.scoreOne = this.assessment_data.find((a: any) => a.email == email);
    if (this.scoreOne === undefined) {
      return 'N/A';
    } else {
      let ans = this.scoreOne.assessment[0][id] || 'N/A';
      return ans;
    }
  }
  getCalBySubSection(id: any, email: any) {
    this.scoreOne = this.assessment_data.find((a: any) => a.email == email);
    if (this.scoreOne === undefined) return 'N/A';
    if (this.scoreOne.calibrator_remark[0] === undefined) {
      return '';
    }
    if (this.scoreOne.calibrator_remark[0][id] === undefined) {
      return '';
    } else return this.scoreOne.calibrator_remark[0][id];
  }
  checksubdone(sec_no: any) {
    if (this.subdone.includes(sec_no)) {
      return false;
    } else {
      this.subdone.push(sec_no);
      return true;
    }
  }
  getAdminBySubSection(id: any, email: any) {

    this.scoreOne = this.assessment_data.find((a: any) => a.email == email);
    if (this.scoreOne === undefined) return 'N/A';
    if (this.scoreOne.admin_remark[0] === undefined) {
      return '';
    }
    if (this.scoreOne.admin_remark[0][id] === undefined) {
      return '';
    } else return this.scoreOne.admin_remark[0][id];
  }
  switchTab(userType: any) {
    let criteria = this.criteria[0];
    if (userType == 'coapplicant') {
      criteria = this.criteria[1];
    }
    this.spinner.show();
    this.activeTab = userType;
    this.SubSecmodel = {};
    this.questions = [];
    this.questModels = {};
    this.current_sec = '';
    this.quest
      .sectionGroupbyCriteria({
        criteria: criteria,
      })
      .subscribe((elem: any) => {
        this.section = elem;
        this.spinner.hide();
      });
  }
  isEmpty(obj: any) {
    return !Object.keys(obj).length
  }
}
